/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // http://foundation.zurb.com/sites/docs/javascript.html#initializing
  $(document).foundation();
  $(document).ready(function () {
    //filter_data();
    function get_filter(class_name) {
      var filter = [];
      $("." + class_name + ":checked").each(function () {
        filter.push($(this).val());
        $(".cat-selector").removeClass("active");
        $(this).parent().parent().addClass("active");
      });
      return filter;
    }

    function filter_data() {
      $(".filter_data").html('<div id="loading" style="" ></div>');
      var action = "fetch_data";
      var category = get_filter("category");
      $.ajax({
        url: templateJS.ajaxURL,
        method: "POST",
        data: {
          action: action,
          category: category,
        },
        success: function (data) {
          $(".filter_data").html(data);
        },
        error: function (xhr, status, error) {
          $(".filter_data").html(
              "<div class='no-data' style='text-align: center;'><i class='far fa-frown' style = 'font-size: 4em; color: #0e488c;'></i><h3 style='font-size:2em; margin-top: 0.5em; color: #0e488c;'>No Data Found</h3></div >"
          );
        },
      });
    }

    $(".common_selector").click(function () {
      if ($(this).attr("name") === "page_id") {
        var originalClicked = $(this).val();
        $("input[name=page_id]").each(function () {
          if ($(this).val() === originalClicked) {
            $(this).attr("checked", true);
          } else {
            $(this).attr("checked", false);
          }
        });
      } else {
        $("input[name=page_id]").each(function () {
          $(this).attr("checked", false);
        });
      }
      filter_data();
    });
  });
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //Toggle menu
        $(".js-menu-training").click(function () {
          $(".menu-training").toggleClass("expanded");
        });

        $(".js-jac-account-form").submit(function (e) {
          e.preventDefault();
          var $this = $(this);

          $this.find("button[type=submit]").prop("disabled", true);
          showLoader();
          $.ajax({
            type: $this.attr("method"),
            dataType: "json",
            url: templateJS.ajaxURL,
            data: {
              action: "jac_account_" + $this.data("action"),
              data: $this.serialize(),
            },
            success: function (r) {
              hideLoader();
              $this.find("button[type=submit]").prop("disabled", false);
              if (r.success) {
                if (r.data && r.data.message) {
                  // if don't need success message, remove class at markup
                  $this.find(".js-success-message").text(r.data.message);
                }

                if (r.data && r.data.hidden) {
                  $this.find(".js-fields").hide("slow");
                }
                if (r.data && r.data.disabled) {
                  $this.find("input,button,textarea").prop("disabled", true);
                }

                if (r.data && r.data.url) {
                  if (r.data.url === "#") {
                    location.reload();
                  } else {
                    setTimeout(function () {
                      location.href = r.data.url;
                    }, 3000);
                  }
                }
              } else {
                if (r.data && r.data.message) {
                  $this.find(".js-error-message").text(r.data.message);
                } else {
                  alert("Something wrong, seriously.");
                }
              }
            },
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    account: {
      init: function () {
        $(".js-slick-heroes").slick({
          dots: false,
          infinite: true,
          arrows: true,
          prevArrow:
            '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 500,
              settings: {
                dots: true,
                arrows: false,
              },
            },
          ],
        });
      },
      finalize: function () {
        myPluploadUploader(".js-cert-uploader", {
          mime_types: [{ title: "Documents", extensions: "pdf" }],
        });
      },
    },
    course: {
      init: function () {
        $(".js-slide").slick({
          dots: true,
          infinite: true,
          arrows: false,
          // prevArrow: $('.js-hero-prev'),
          // nextArrow: $('.js-hero-next'),
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
        });
        if ($(".js-questions-test").length) {
          $(".js-questions-test").slick({
            infinite: false,
            arrows: false,
            nextArrow: $(".js-next-question"),
            fade: true,
            // adaptiveHeight: true,
            draggable: false,
          });

          $(".js-next-question").click(function (e) {
            e.preventDefault();
            var $current = $(".slick-current");
            var $checked = $current.find("input[type=radio]:checked");
            $(".js-validate-answer").addClass("display-block");
            $(".js-next-question").removeClass("display-block");
            if ($checked.length) {
              $(".js-questions-test").slick("slickNext");
              $(".section-notification p").remove();
              $(".section-notification").removeClass("active");
            } else {
              $(".section-notification").addClass("active");
              $(".section-notification").prepend(
                "<p>Please choose an answer.</p>"
              );
            }
          });
          $(".js-validate-answer").click(function (e) {
            e.preventDefault();

            var $current = $(".slick-current");
            var $checked = $current.find("input[type=radio]:checked");

            if ($checked.length) {
              $current.find("input[type=radio]").attr("disabled", true);
              $(".js-validate-answer").removeClass("display-block");
              $(".js-next-question").addClass("display-block");
              if ($checked.data("bool") === "yes") {
                $(".section-notification").addClass("active");
                $(".section-notification").html(
                  "<p>You selected the correct answer.</p>"
                );
              } else {
                $(".section-notification").addClass("active");
                $(".section-notification").html(
                  "<p><strong>Incorrect: </strong>Right answer is " +
                    $current.find('input[data-bool="yes"]').data("full") +
                    "</p>"
                );
              }
            } else {
              $(".section-notification").addClass("active");
              $(".section-notification").prepend(
                "<p>Please choose an answer.</p>"
              );
            }
          });
        }

        if ($(".js-questions-exam").length) {
          $(".js-questions-exam").slick({
            infinite: false,
            arrows: false,
            nextArrow: $(".js-next-question"),
            fade: true,
            // adaptiveHeight: true,
            draggable: false,
          });

          $(".js-next-question").click(function (e) {
            e.preventDefault();
            var $current = $(".slick-current");
            var $checked = $current.find("input[type=radio]:checked");
            if ($checked.length) {
              $(".js-questions-exam").slick("slickNext");
              $(".section-notification p").remove();
              $(".section-notification").removeClass("active");
            } else {
              $(".section-notification").addClass("active");
              $(".section-notification").prepend(
                "<p>Please choose an answer.</p>"
              );
            }
          });
        }

        var courseParentID = $(".course-main").attr("id");
        var chapterID = $(".chapters").attr("id");
        var totalString = ".chapters#" + courseParentID;
        var totalStringi = ".chapters#" + courseParentID + " i";
        var totalStringh3 = ".chapters#" + courseParentID + " h3";
        var totalStringh4 = ".chapters#" + courseParentID + " h4";
        var totalStringp = ".chapters#" + courseParentID + " p";
        $(totalString).parent().css("background", "#313131");
        $(totalStringi).css("color", "white");
        $(totalStringh3).css("color", "white");
        $(totalStringh4).css("color", "white");
        $(totalStringp).css("color", "white");
      },
      finalize: function () {
        $('.js-children-no-click a[href^="#"]').click(function (e) {
          e.preventDefault();
        });

        $(".js-player").each(function () {
          var $player = $(this);

          if (!$player.data("src")) {
            $player.html("Error: Audio file is not found.");
            return;
          }

          var mySound = new buzz.sound($player.data("src"), {
            autoplay: true,
          });
          // console.log(mySound);
          mySound
            .bind("ended", function () {
              // ajax tell server is played
              $.ajax({
                type: "post",
                dataType: "json",
                url: templateJS.ajaxURL,
                data: {
                  action: "course_audio_played",
                  data: {
                    id1: $player.data("id1"), // course id
                    id2: $player.data("id2"), // progress id
                  },
                },
                success: function (r) {
                  if (r.success) {
                    $player.find(".js-next").prop("disabled", false);
                  } else {
                    alert("Something wrong. Please try again or contact us.");
                  }
                },
              });
              // $player.find('.js-next').data('src', 'https://www.google.com');
            })
            .bind("timeupdate", function () {
              // console.log(this.getPercent());
              $player.find(".js-progress").width(this.getPercent() + "%");
            })
            .bind("play", function () {
              if (this.isEnded()) {
                $player.find(".js-progress").width(0);
              }
              $player
                .find(".js-toggle")
                .removeClass("fa-play")
                .addClass("fa-pause");
            })
            .bind("pause", function () {
              $player
                .find(".js-toggle")
                .removeClass("fa-pause")
                .addClass("fa-play");
            });

          $player.find(".js-restart").click(function () {
            if (mySound.isPaused()) {
              $player.find(".js-progress").width(0);
              mySound.setPercent(0);
            }
          });

          $player.find(".js-toggle").click(function () {
            mySound.togglePlay();
          });

          $player.find(".js-next").click(function () {
            // var $this = $(this);
            // if ($this.data('src')) {
            //   location.href = $this.data('src');
            // } else {
            location.reload();
            // }
          });
          // $player.find('.js-previous').click(function () {
          // var $this = $(this);
          // if ($this.data('src')) {
          //   var previous = $player.data('lastsrc');
          //   var previous_id = $player.data('id3');
          // console.log(previous);
          // location.reload();

          // $player.attr('data-src',previous);
          // $player.attr('data-id2',previous_id);

          // }
          // });
        });

        $(".js-previous").click(function (e) {
          e.preventDefault();
          var $this = $(this);

          $.ajax({
            type: "post",
            dataType: "json",
            url: templateJS.ajaxURL,
            data: {
              action: "course_audio_played_previous",
              data: {
                id1: $this.data("id1"), // course id
                id2: $this.data("id2"), // progress id
              },
            },
            success: function (r) {
              if (r.success) {
                location.reload();
              } else {
                alert("Something wrong. Please try again or contact us.");
              }
            },
          });
        });

        $(".js-continue").click(function (e) {
          e.preventDefault();
          var $this = $(this);

          $.ajax({
            type: "post",
            dataType: "json",
            url: templateJS.ajaxURL,
            data: {
              action: "course_audio_played",
              data: {
                id1: $this.data("id1"), // course id
                id2: $this.data("id2"), // progress id
              },
            },
            success: function (r) {
              if (r.success) {
                location.reload();
              } else {
                alert("Something wrong. Please try again or contact us.");
              }
            },
          });
        });

        $(".js-submit").click(function (e) {
          e.preventDefault();
          var $this = $(this);

          $.ajax({
            type: "post",
            dataType: "json",
            url: templateJS.ajaxURL,
            data: {
              action: "check_exam",
              id1: $this.data("id1"), // course id
              id2: $this.data("id2"), // exam post id
              data: $(".js-question").serialize(), // answer array [question1 => answer1, question2 => answer2]
            },
            success: function (r) {
              if (r.success) {
                if (r.data.bool) {
                  // alert(r.data.message);
                  $text = r.data.message;
                  $("#myModal").addClass("active");
                  $(".section-notification").addClass("active");
                  $(".section-notification").html("<p>" + $text + "</p>");
                  $("#text-pass").html($text);
                  $("#myModal").css("display", "block");
                  // location.href = r.data.url;
                } else {
                  $text = r.data.message;
                  var pageURL = $(location).attr("href");
                  $("#text-status").html("Failed");
                  $("#myModal").addClass("active");
                  $(".section-notification").addClass("active");
                  $(".section-notification").html("<p>" + $text + "</p>");
                  $(".close-popup").attr("href", pageURL);
                  $(".close-popup").html("Retake");
                  $("#text-pass").html($text);
                  $("#myModal").css("display", "block");
                  // alert(r.data.message);
                  // location.reload();
                }
              } else {
                alert("Something wrong. Please try again or contact us.");
              }
            },
          });
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

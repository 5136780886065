var
  defaultFor = function (arg, val) {
    return typeof arg !== 'undefined' ? arg : val;
  },
  showLoader = function () {
    // jObj = defaultFor(jObj, 'body');
    // if (!jObj.jquery && jQuery.type(jObj) === 'string') {
    //   jObj = jQuery(jObj);
    // }
    // jObj.addClass('jac-loading').removeClass('jac-loaded');
    jQuery('#ajax-loader').show();
  },
  hideLoader = function () {
    // jObj = defaultFor(jObj, 'body');
    // if (!jObj.jquery && jQuery.type(jObj) === 'string') {
    //   jObj = jQuery(jObj);
    // }
    // jObj.removeClass('jac-loading').addClass('jac-loaded');
    jQuery('#ajax-loader').hide();
  },
  myMediaUploader = function (scope, multiple, key, size) {

    if (!scope.length) {
      return;
    }

    var frame,
      addLink = scope.find('.js-add-link'),
      delLink = scope.find('.js-delete-link'),
      imgContainer = scope.find('.js-img-container'),
      inputContainer = scope.find('.js-input-container');
    multiple = defaultFor(multiple, false);
    key = defaultFor(key, 'key');
    size = defaultFor(size, 'full');

    addLink.on('click', function (event) {

      event.preventDefault();

      // If the media frame already exists, reopen it.
      if (frame) {
        frame.open();
        return;
      }

      // Create a new media frame
      frame = wp.media({
        title: 'Select or Upload Media Of Your Chosen Persuasion',
        button: {
          text: 'Use this media'
        },
        multiple: multiple  // Set to true to allow multiple files to be selected
      });

      // When an image is selected in the media frame...
      frame.on('select', function () {

        // Get media attachment details from the frame state
        var selected = frame.state().get('selection').toJSON();

        // Init the result array
        var result = [];

        for (var i = 0; i < selected.length; i++) {

          var url, height, width;

          if (selected[i].type !== 'image') {

            if (typeof selected[i].image !== 'undefined') {
              url = selected[i].image.src;
              height = selected[i].image.height;
              width = selected[i].image.width;
            } else {
              url = selected[i].icon;
              height = 64;
              width = 48;
              //TODO: need better solution
            }
          } else {
            if (size !== 'full' && typeof selected[i].sizes[size] !== 'undefined') {
              url = selected[i].sizes[size].url;
              height = selected[i].sizes[size].height;
              width = selected[i].sizes[size].width;
            } else {
              url = selected[i].url;
              height = selected[i].height;
              width = selected[i].width;
            }
          }

          result.push({
            "id": selected[i].id,
            "url": url,
            "height": height,
            "width": width,
            "title": selected[i].title
          });

        }

        if (multiple === false) {

          imgContainer.append('<img src="' + result[0].url + '" alt="' + result[0].title + '" />');
          inputContainer.append('<input name="' + key + '" type="hidden" value="' + result[0].id + '" />');

        } else {

          for (i = 0; i < result.length; i++) {
            imgContainer.append('<img src="' + result[i].url + '" alt="' + result[i].title + '" />');
            inputContainer.append('<input name="' + key + '[]" type="hidden" value="' + result[i].id + '" />');
          }

        }

        addLink.hide();
        delLink.show();

      });

      // Finally, open the modal on click
      frame.open();
    });

    delLink.on('click', function (event) {

      event.preventDefault();

      imgContainer.empty();
      inputContainer.empty().append('<input name="' + key + '" type="hidden" value="" />');

      addLink.show();
      delLink.hide();

    });

  },
  myPluploadUploader = function (scope, params) {

    // console.log('myPluploadUploader: ' + scope);

    if (!scope.length) {
      return;
    }
    if (!scope.jquery && jQuery.type(scope) === 'string') {
      scope = jQuery(scope);
    }

    var ajax_url = scope.data('url');
    var ajax_nonce = scope.data('nonce');
    var ajax_action = scope.data('action');

    if (!ajax_url || !ajax_nonce || !ajax_action) {
      return;
    }

    var browse_button = scope.find('.js-plupload-browse-button').prop('id', 'browse-button-' + ajax_nonce).prop('id');
    var container = scope.find('.js-plupload-container').prop('id', 'container-' + ajax_nonce).prop('id');
    var drop_element = scope.find('.js-plupload-drop-element').prop('id', 'drop-element-' + ajax_nonce).prop('id');

    params = defaultFor(params, {});
    params.max_file_size = defaultFor(params.max_file_size, '20mb');
    params.mime_types = defaultFor(params.mime_types, [
      {title: "Image files", extensions: "jpg,jpeg,gif,png"}
    ]);

    var uploader = new plupload.Uploader({
      runtimes: 'html5',
      file_data_name: 'plupload-upload',
      url: ajax_url,
      filters: {
        max_file_size: params.max_file_size,
        mime_types: params.mime_types
      },
      multi_selection: false,
      // urlstream_upload: true,
      // multiple_queues: true,

      browse_button: browse_button,
      // container: container,
      drop_element: drop_element,

      multipart: true,
      multipart_params: {
        action: ajax_action,
        nonce: ajax_nonce
      }

    });

    uploader.bind('Init', function (up) {

      var uploaddiv = scope.find('#' + container);

      if (up.features.dragdrop) {
        uploaddiv.addClass('drag-drop');

        scope.find('#' + drop_element)
          .bind('dragover', function () {
            uploaddiv.addClass('drag-over');
          })
          .bind('dragleave.drag-drop, drop.drag-drop', function () {
            uploaddiv.removeClass('drag-over');
          });
      } else {
        uploaddiv.removeClass('drag-drop');
        scope.find('#' + drop_element).unbind('#' + drop_element);
      }

    });

    uploader.init();

    uploader.bind('FilesAdded', function (up, files) {
      up.refresh();

      // plupload.each(files, function(file) {
      // });

      showLoader();
      up.start();
    });

    uploader.bind('FileUploaded', function (up, file, response) {

      // console.log(response);

      hideLoader();
      scope.find('.js-plupload-response').html(response.response);

      // scope.find('.js-plupload-uploader').hide();
      // scope.find('#' + browse_button).hide();
      // scope.find('#' + container).hide();
      // scope.find('#' + drop_element).hide();
    });

  },
  myAdminGallery = function (scope, field) {

    if (!scope.length) {
      return;
    }
    if (!scope.jquery && jQuery.type(scope) === 'string') {
      scope = jQuery(scope);
    }

    var frame, images;

    scope.on('click', '.js-gallery2-add-images', function (e) {

      e.preventDefault();
      var $this = jQuery(this);

      // If the media frame already exists, reopen it.
      if (frame) {
        frame.open();
        return;
      }

      // Create a new media frame
      frame = wp.media({
        title: 'Upload or select images (hold shift/ctrl key for multi images)',
        library: {
          type: 'image'
        },
        button: {
          text: 'Add images'
        },
        multiple: true  // Set to true to allow multiple files to be selected
      });

      // When an image is selected in the media frame...
      frame.on('select', function () {

        // Get media attachment details from the frame state
        images = frame.state().get('selection').toJSON();

        jQuery.ajax({
          type: 'post',
          dataType: 'html',
          url: ajaxurl,
          data: {
            action: 'jac_gallery2_select_images',
            post: $this.data('post'),
            images: images,
            field: field
          },
          success: function (r) {
            scope.find('.js-gallery2-images').append(r);
          }
        });

      });

      // Finally, open the modal on click
      frame.open();

    });

    scope.on('click', '.js-gallery2-delete-image', function (e) {

      e.preventDefault();

      var $this = jQuery(this);

      if ($this.closest('.js-gallery2-image').hasClass('js-unsaved')) {
        $this.closest('.js-gallery2-image').hide('slow', function () {
          jQuery(this).remove();
        });
      } else {
        jQuery.ajax({
          type: 'post',
          dataType: 'json',
          url: ajaxurl,
          data: {
            action: 'jac_gallery2_delete_image',
            post: $this.data('post'),
            image: $this.data('image'),
            field: field
          },
          success: function (r) {
            if (r.success) {
              $this.closest('.js-gallery2-image').hide('slow', function () {
                jQuery(this).remove();
              });
            } else {
              alert('Error! Please refresh and try again.');
            }
          }
        });
      }

    });
  }
;

window.Parsley
  .addValidator('phone', {
    validateString: function(value) {
      return /^([\+][0-9]{1,3}([ \.\-])?)?([\(][0-9]{1,6}[\)])?([0-9 \.\-]{1,32})(([A-Za-z \:]{1,11})?[0-9]{1,4}?)$/.test(value);
    },
    messages: {
      en: 'This value should be a valid phone.'
    }
  });

// window.ParsleyConfig.classHandler = function (ParsleyField) {
//   return ParsleyField.$element.parent();
// };
